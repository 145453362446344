<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
            >
              <el-form-item label="垃圾收集点名称" prop="collectName" style="margin-bottom: 0px">
                <el-input v-model="form.collectName" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="开放时间" prop="validity" style="width: 100%">
                <el-time-select
                  placeholder="起始时间"
                  v-model="form.openStartTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                  }"
                ></el-time-select>至
                <el-time-select
                  placeholder="结束时间"
                  v-model="form.openEndTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    minTime: form.openStartTime
                  }"
                ></el-time-select>
              </el-form-item>
              <el-form-item label="管理单位名称">
                <el-input v-model="form.manageUnit" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="收集点类型">
                <el-select
                  v-model="form.collectType"
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.collectType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="垃圾收集桶数量(个)">
                <el-input v-model="form.garbageCollectNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="垃圾桶类型">
                <el-select
                  v-model="form.trashType"
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.trashType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="每天清运次数(次)">
                <el-input v-model="form.cleanTonDaily" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="清运量(吨/天)">
                <el-input v-model="form.cleanTon" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="水电设施是否完善">
                <el-input v-model="form.facilitiesSituation" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="地址" style="width: 100%">
                <el-input v-model="form.collectAddress" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <!-- 垃圾收集点附件-->
              <el-form-item label="垃圾收集点附件" style="width: 100%">
                <ayl-upload-single-image v-model="form.collectImageUrls"></ayl-upload-single-image>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData" icon="ljsjd"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],
  
  data() {
    return {
      nav: [{ name: "编辑垃圾收集点" }],
      mapData: {
        //地图数据
        lnglat: null, //经纬度，数组，形如[118,36]
        scope: null //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      },
      form: {
        collectName: null, //垃圾收集点名称
        bidId: null, //所属标段
        openStartTime: null, //开始时间
        openEndTime: null, //结束时间
        manageUnit: null, //管理单位名称
        collectType: null, //收集点类型
        garbageCollectNum: null, //垃圾收集桶数量
        trashType: null, //垃圾桶类型
        cleanTonDaily: null, //每天清运次数
        cleanTon: null, //清运量
        facilitiesSituation: null, //水电设施是否完善
        collectAddress: null, //地址
        collectImageUrls: null //附件
      },
      rules: {
        collectName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: "请输入1-20位垃圾收集点名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        }
      }
    };
  },
  methods: {
    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let self = this;
      //提交form数据
      await this.$api_hw.refuseCollectionPoint_update({
        sanitationRubbishCollectVO: _fixData(self.form)
      });
      //修剪form的数据
      function _fixData(d) {
        //补字段
        if (self.mapData) {
          if (self.mapData.lnglat)
            d["collectCoordinateScope"] = JSON.stringify(self.mapData.lnglat);
          else
            d["collectCoordinateScope"] = "";
          d["isDrawn"] = self.mapData.lnglat ? 1 : 0;
        }
        self.log(d);
        return d;
      }
    },
    async getInfo() {
      let self = this;
      let formData = await self.$api_hw.refuseCollectionPoint_getById({
        id: self.$route.query.id
      });
      self.form = _fixData(formData);
      //修剪form的数据以方便编辑
      function _fixData(d) {
        //先检查key是否存在，不存在赋值null
        ["collectCoordinateScope", "bidScope"].forEach(key => {
          if (!d.hasOwnProperty(key)) {
            d[key] = null;
          }
        });
        //然后对key对应的值做处理
        for (let key in d) {
          switch (key) {
            // case "isContainedWc":
            // case "isSingle":
            //   d[key] = !!d[key];
            //   break;
            case "collectCoordinateScope":
              try {
                self.mapData.lnglat = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.lnglat = null;
              }
              self.log("mapData.lnglat", self.mapData.lnglat);
              break;
            case "bidScope":
              try {
                self.mapData.scope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.scope = null;
              }
              break;
          }
        }
        return d;
      }
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  margin: 20px 0
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
